<template>
  <a-link
    class="premium-banner__wrapper"
    :to="generatePremiumUrl()"
    aria-label="Go to ForexLive Premium"
  >
    <a-image
      :src="$helper.getLinkToFlBucketFile('premium-banner-lg.png')"
      :alt="$options.consts.altText"
      :aspect-ratio="$options.consts.aspectRatios.premiumBannerLg"
      class="premium-banner__image-lg"
    />
    <a-image
      :src="$helper.getLinkToFlBucketFile('premium-banner-sm.png')"
      :alt="$options.consts.altText"
      :aspect-ratio="$options.consts.aspectRatios.premiumBannerSm"
      class="premium-banner__image-sm"
    />
  </a-link>
</template>

<script>
import mixins from '@/utils/mixins'
import { aspectRatios } from 'enums/aspectRatios'

export default {
  name: 'PremiumBanner',
  mixins: [mixins.urlGenerators],
  consts: {
    aspectRatios,
    altText: 'ForexLive Premium'
  }
}
</script>

<style lang="scss" scoped>
.premium-banner__wrapper {
  display: block;
  margin-top: 20px;

  .premium-banner__image-lg {
    width: $sidebar-width-lg;

    /deep/ img {
      width: $sidebar-width-lg;
      height: 78px;
    }

    @include sidebar-small-size {
      display: none;
    }
  }

  .premium-banner__image-sm {
    width: $sidebar-width-sm;
    display: none;

    /deep/ img {
      width: $sidebar-width-sm;
      height: 64px;
    }

    @include sidebar-small-size {
      display: block;
    }
  }
}
</style>
